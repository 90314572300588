import { atom, useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useModal } from "mui-modal-provider";
import { ActiveQuest, QuestGroup, QuestsProgress, QuestStep } from "../../components/quests/quests.types";
import {
  getNextQuestStep,
  getQuestConfigFromActiveQuest,
  getQuestsStepConfig,
} from "../../components/quests/quests.util";
import { QuestVideoPlayerModal } from "../../components/quests/QuestVideoPlayerModal";
import { LOCAL_STORAGE_ACTIVE_QUESTS } from "../../consts/storageKeys";
import { reclaim } from "../../reclaim-api";
import { useCallbackSafeRef } from "../useCallbackSafeRef";
import { useNotifications } from "../useNotifications";
import { useOurRouter } from "../useOurRouter";

const activeQuestAtom = atomWithStorage<ActiveQuest<QuestGroup> | undefined>(LOCAL_STORAGE_ACTIVE_QUESTS, undefined);
const questsAtom = atom<QuestsProgress | undefined>(undefined);
const loadingQuestsAtom = atom<boolean>(false);

export type UseQuestsStateReturnType = {
  activeQuest?: ActiveQuest<QuestGroup>;
  quests?: QuestsProgress;
  questsLoading: boolean;
};

export const useQuestsState = (): UseQuestsStateReturnType => {
  const [quests] = useAtom(questsAtom);
  const [activeQuest] = useAtom(activeQuestAtom);
  const [questsLoading] = useAtom(loadingQuestsAtom);

  return { activeQuest, quests, questsLoading };
};

export type UseQuestsActionsReturnType = {
  loadQuests: () => Promise<QuestsProgress | undefined>;
  setActiveQuest: (quest: ActiveQuest<QuestGroup> | undefined) => void;
  completeQuest: (quest: ActiveQuest<QuestGroup>) => void;
  onStepComplete: (step: QuestStep) => void; 
};

export const useQuestsActions = (): UseQuestsActionsReturnType => {
  const [activeQuest, _setActiveQuest] = useAtom(activeQuestAtom);
  const [quests, setQuests] = useAtom(questsAtom);
  const [, setQuestsLoading] = useAtom(loadingQuestsAtom);

  const router = useOurRouter();

  const { showModal } = useModal();
  const { sendNotification } = useNotifications();

  const loadQuests = useCallbackSafeRef(async () => {
    setQuestsLoading(true);

    try {
      const quests = await reclaim.users.getCompletedQuests();
      setQuests(quests);
      return quests;
    } catch (cause) {
      // TODO (RAI-7525)
    } finally {
      setQuestsLoading(false);
    }
  });

  const completeQuest = useCallbackSafeRef(async (activeQuest: ActiveQuest<QuestGroup>) => {
    _setActiveQuest(undefined);
    const quest = getQuestConfigFromActiveQuest(activeQuest);
    const questComplete = !!quests?.[activeQuest.group]?.quests?.[activeQuest.quest]?.complete;

    if (!quest?.indeterminant && !questComplete) {
      try {
        const newQuests = await reclaim.users.completeQuest(activeQuest.quest);
        setQuests(newQuests);
      } catch (cause) {
        // TODO (RAI-7525)
      }
    } 
    
    if (!questComplete && quest && (!quest.indeterminant || quest.completionNotification)) {
      sendNotification({ message: `"${quest.title}" quest complete!` });
    }
  });

  const setNextStepAsActive = useCallbackSafeRef((quest: ActiveQuest<QuestGroup>) => {
    const next = getNextQuestStep(quest);
    !!next ? _setActiveQuest({ ...quest, step: next.id }) : void completeQuest(quest);
  });

  const setActiveQuest = useCallbackSafeRef((quest: ActiveQuest<QuestGroup> | undefined) => {
    if (quest) {
      const step = getQuestsStepConfig(quest);
      if (!step) {
        // TODO (RAI-7525) This is a configuration error that needs to be handled.
        _setActiveQuest(undefined);
      } else {
        switch (step.type) {
          case "url": {
            if (!step.url) {
              throw new Error("Must provide a url for url quest step");
            }
            window.open(step.url, "reclaim_slackintegration");
            setNextStepAsActive(quest);
            break;
          }
          case "redirect": {
            if (!step.redirect) {
              throw new Error("Must provide a redirect path for redirect quest step");
            }
            void router.push(step.redirect);
            setNextStepAsActive(quest);
            break;
          }
          case "video": {
            _setActiveQuest(quest);

            if (!step.videoLink) {
              throw new Error("Must provide a video embed link to play video");
            }

            showModal(QuestVideoPlayerModal, { step });
            break;
          }
          case "orb-click":
          case "orb": {
            _setActiveQuest(quest);
          }
        }
      }
    } else {
      _setActiveQuest(undefined);
    }
  });

  const onStepComplete = useCallbackSafeRef(() => {
    if (!activeQuest) return;

    const next = getNextQuestStep(activeQuest);
    if (next) {
      setActiveQuest({ ...activeQuest, step: next.id });
    } else {
      void completeQuest(activeQuest);
    }
  });

  return { setActiveQuest, completeQuest, onStepComplete, loadQuests };
};
